<template>
  <div class="content-block">
    <h2 class="lg-title nb">
      <div class="title-word">採購明細表</div>
      <div class="cta-tool">
        <div class="cta-btn detail" @click="exportGroupProductDetails"></div>
      </div>
    </h2>
    <div class="container">
      <div class="data-container detail-list">
        <div class="data-head">
          <div class="data-row">
            <div class="item product">品項</div>
            <div class="item qty">數量</div>
            <div class="item price">銷售金額</div>
            <div class="item edit"></div>
          </div>
        </div>
        <div class="data-body">
          <div
            class="data-row"
            :class="{ active: status[index] === true }"
            v-for="(product, index) in products"
            :key="`purchase-table-products-${index}`"
          >
            <div class="item product">
              {{ product.name }}
              <small style="color:#E30000;">
                {{ product.spec_name }}
              </small>
            </div>
            <div class="item qty">{{ product.actual_quantity }}</div>
            <div class="item price">${{ product.subtotal }}</div>
            <div class="item edit">
              <div
                class="edit-btn"
                :class="{ active: status[index] === true }"
                :data-product-index="index"
                @click="clickEditBtn"
              ></div>
            </div>
            <div class="edit-row-wrap">
              <div
                class="edit-row"
                v-for="detail in product.details"
                :key="`purchase-table-products-${index}-details-${detail.id}`"
              >
                <div class="buyer">{{ detail.member.name }}</div>
                <div class="qty">{{ detail.actual_quantity }}</div>
                <div class="price">${{ detail.subtotal }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExportGroupProductsListSignedUrl } from '@/api/group-buying';

export default {
  props: {
    products: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      status: [] //列表打開狀態
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  watch: {
    products: {
      immediate: true,
      handler() {
        this.initStatus();
      }
    }
  },
  methods: {
    initStatus() {
      this.products.forEach(() => {
        this.status.push(false);
      });
    },
    clickEditBtn(e) {
      let productIndex = e.target.dataset.productIndex;
      let status = this.status[productIndex];

      this.$set(this.status, productIndex, !status);
    },
    exportGroupProductDetails() {
      getExportGroupProductsListSignedUrl({
        group_buying_order_id: this.groupId
      }).then(data => {
        window.open(data);
      });
    }
  }
};
</script>
