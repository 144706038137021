import { getPurchaseGroupBuyingProductList } from '@/api/group-buying';
import { get, groupBy } from 'lodash';

export default {
  props: {
    fromWebsite: {
      required: false,
      type: Boolean,
      handler() {
        return false;
      }
    },
    orderWebsite: {
      required: false,
      type: Boolean,
      handler() {
        return false;
      }
    }
  },
  data() {
    return {
      group: null
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    orderFrom() {
      return this.orderWebsite === true ? 1 : 2;
    },
    groupSubtotal() {
      return get(this.group, 'subtotal');
    },
    groupBuyingOrderDetails() {
      return get(this.group, 'group_buying_order_details', []);
    },
    groupProducts() {
      let products = get(this.group, 'group_buying_products', []);

      //加入details選單
      products.map((ele, index) => {
        ele.details = this.groupBuyingOrderDetails.filter(el => {
          return ele.id === el.group_buying_product_id;
        });
        return ele;
      });

      return products;
    },
    mainProducts() {
      return groupBy(this.groupProducts, 'main_product_id');
    },
    //用來判斷是否達到可下訂單門檻
    amtReach() {
      let flag = true;
      if (this.fromWebsite === false) {
        return flag;
      }

      this.groupProducts.some(element => {
        if (element.unit_quantities.mini.quantity > 0) {
          flag = false;
          return true;
        }
      });

      return flag;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.setData();
      }
    }
  },
  methods: {
    setData() {
      getPurchaseGroupBuyingProductList({
        group_buying_order_id: this.groupId,
        order_from: this.orderFrom
      })
        .then(data => {
          this.group = data;
        })
        .catch(() => {
          this.toManageMenu();
        });
    },
    toManageMenu() {
      this.$router.push({
        name: 'CreatedGroupManage',
        params: {
          group_id: this.groupId
        }
      });
    }
  }
};
