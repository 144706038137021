<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title nb">
        <div class="title-word">產品追加</div>
        <div class="cta-tool">
          <router-link
            class="cta-btn add"
            :to="{
              name: 'CreatedGroupManageAddition',
              params: $route.params,
              query: $route.query
            }"
          ></router-link>
        </div>
      </h2>
      <div class="data-container list add-list">
        <div class="data-body">
          <template v-for="groupMainProduct in mainProducts">
            <div
              class="data-row"
              :class="{ done: amtReach }"
              v-for="(product, index) in groupMainProduct"
              :key="`products-sum-list-product-${product.id}`"
            >
              <div class="item product">
                {{ product.name }}
                <small>({{ product.spec_name }})</small>
              </div>
              <div class="item qty">
                {{ product.actual_quantity }}
                <span>{{ product.unit }}</span>
              </div>
              <div class="item qty-detail" v-if="orderWebsite">
                <div class="text">
                  <template v-if="index == groupMainProduct.length - 1">
                    {{ product.unit_quantities.unit.quantity }}
                    <span>{{ product.unit_quantities.unit.text }}</span>

                    <template v-if="product.unit_quantities.mini.quantity > 0">
                      <e>{{ product.unit_quantities.mini.quantity }}</e>
                      <span>{{ product.unit_quantities.mini.text }}</span>
                    </template>
                  </template>
                </div>
                <div class="sum" v-if="index == groupMainProduct.length - 1">
                  ${{
                    orderWebsite
                      ? product.product_from_web_subtotal
                      : product.subtotal
                  }}
                </div>
              </div>
            </div>
          </template>
          <div class="sum-block">${{ groupSubtotal }}</div>
        </div>
      </div>
    </div>
    <PurchaseTable :products="groupProducts"></PurchaseTable>
    <div class="bottom-btn-block">
      <a class="btn-cta" @click.prevent="finished">
        {{ finishedBtnText }}
      </a>
    </div>
    <div
      class="modal fade"
      id="modal-helper-unfull"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">未滿箱數量</div>
          <div class="helper-content">
            請以網站基準單位下單，可進行增減單作業或追加來湊足整數
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn" data-dismiss="modal">確定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseTable from '@/components/GroupManage/PurchaseTable';
import { finishedStep2 } from '@/api/group-buying';
import GroupPurchaseMixin from '@/mixins/group-purchase';
import { groupBy } from 'lodash';

export default {
  mixins: [GroupPurchaseMixin],
  components: {
    PurchaseTable
  },
  computed: {
    finishedBtnText() {
      return this.orderWebsite ? '確認向網站訂購' : '完成產品追加';
    }
  },
  methods: {
    finished() {
      this.orderWebsite ? this.orderFromWebsite() : this.finishedStep2();
    },
    orderFromWebsite() {
      if (this.amtReach) {
        this.$router.push({
          name: 'CreatedGroupManageCheckout',
          params: this.$route.params,
          query: this.$route.query
        });
      } else {
        $('#modal-helper-unfull').modal('show');
      }
    },
    finishedStep2() {
      finishedStep2({
        group_buying_order_id: this.groupId
      }).then(() => {
        this.toManageMenu();
      });
    }
  }
};
</script>
